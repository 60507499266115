import PropTypes from "prop-types";
import clsx from "clsx";
import { IconAlertTriangle } from "components/icons/IconAlertTriangle";
import { IconDone } from "components/icons/IconDone";
import { IconPlus } from "components/icons/IconPlus";
import utils from 'styles/globals/utils.module.scss';
import Style from './DisplayName.module.scss'
import { blurImage } from 'lib/Constants/blurImage';
import { isValidElement, useEffect, useState } from "react";
import SelectedDisplay from "./SelectedDisplay";
import SetExtraDisplay from "./SetExtraDisplay";
import HelpText from "./HelpText";


function DisplayName({ 
    name, 
    hasError = true, 
    step, 
    isOpen = false, 
    isSelected, 
    productSetLength,
    products = [],
    contextState,
    length,
    selectedOption = null,
    isStepCallout = false,
    availabilityTitle = "option",
    helpText = null,
    stepClassName = ""
}){
    const [ open, setOpen ] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [ isOpen ])


    return(
        <summary className={Style.block} onClick={() => setOpen(!open)}>
            <div className={Style.label}>
                {(hasError && !isSelected) ? (
                    <span className={clsx(utils.fs_4, Style.step, Style.stepError)}>
                        <IconAlertTriangle 
                            width="25" 
                            height="25" 
                            className={Style.stepIconError} 
                        />
                    </span>

                ):(
                    <>
                        {isValidElement(step) ? (
                            <span className={clsx(utils.fs_4, Style.step, (isStepCallout ? Style.stepCallout : Style.stepIcon), stepClassName)}>
                                {step}
                            </span>

                        ):(
                            
                            <span className={clsx(utils.fs_4, Style.step, stepClassName, isSelected && Style.stepCheck)}>
                                {isSelected ? <IconDone width="30" height="30" className={Style.stepIcon} /> : `${step}` }
                            </span>
                        )}
                    </>
                )}
    
                <div className={Style.text}>
                    <span className={clsx(utils.fs_4, Style.name)}>
                        {((length && !selectedOption?.title) && !selectedOption?.[0]?.title) ? (
                            <small className={Style.optionsLength}>
                                {length} {availabilityTitle}{length > 1 && "s"} available
                            </small>
                        ):null}
                        
                        {name}

                        <SelectedDisplay 
                            {...{ name, selectedOption, length, availabilityTitle, blurImage }} 
                        />

                        {helpText && (
                            <HelpText>
                                {helpText}
                            </HelpText>
                        )}
                    </span>

                    {contextState === "sets" && (
                        <SetExtraDisplay {...{ productSetLength, products }} />
                    )}

                    {(hasError && !isSelected) && (
                        <strong className={clsx(utils.text_terracotta, Style.error)}>
                            <IconAlertTriangle width="15" height="15" fill="#db613a" />&nbsp;Please choose a customization!
                        </strong>
                    )}
                </div>
            </div>

            <IconPlus className={clsx(Style.toggleIcon, open && Style.iconRotate)} />
        </summary>
    )
}


DisplayName.propTypes = {
    name: PropTypes.string,
    hasError: PropTypes.bool,
    selectedOption: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
    isOpen: PropTypes.bool,
    isSelected: PropTypes.bool,
    productSetLength: PropTypes.number,
    products: PropTypes.array,
    contextState: PropTypes.string,
    length: PropTypes.number,
    step: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    isStepCallout: PropTypes.bool,
    availabilityTitle: PropTypes.string,
    helpText: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    stepClassName: PropTypes.string
}


export default DisplayName;