import clsx from 'clsx';
import Style from './Divider.module.scss';


function OrDivider({ isWhite }){
    return(
        <div className={clsx(Style.or, isWhite && Style.whiteOr)}>
            <span>OR</span>
        </div>
    )
}

export default OrDivider;