/* eslint-disable react-hooks/exhaustive-deps */
import { getFileName } from "components/applications/plp/components/Pod/Pod.helpers";
import { blurImage } from 'lib/Constants/blurImage';
import { cloudinaryProductImageLoader } from "thirdparty/Cloudinary/cloudinaryLoader";
import Image from "next/image";
import { useEffect, useState } from "react";


function SliderImage({ image_url = "", description, width, height, isLoaded = false }){
    const [ fileName, setFileName ] = useState(blurImage)


    useEffect(() => {
        const fileName = image_url ? getFileName(image_url) : null;
        setFileName(fileName)

    }, [])

    
    return(
        <Image 
            src={isLoaded ? fileName : blurImage}
            alt={description ?? `thumbnail`} 
            width={width}
            height={height}
            placeholder="blur"
            blurDataURL={blurImage}
            loader={cloudinaryProductImageLoader}
        />
    )
}

export default SliderImage;