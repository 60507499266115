"use client"

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ErrorBoundary from 'services/ErrorBoundary';
import sortObjectArrayByKey from "lib/Utils/sortObjectArrayByKey";
import Truncate from "components/display/Truncate";
import { useToolTips } from "components/help/ToolTip/useToolTips";
import ToolTip from "components/help/ToolTip";
import Image from "next/image";
import Style from './SpecsTable.module.scss'
import DetailStyle from '../Details.module.scss';
import utils from 'styles/globals/utils.module.scss';


function SpecsTable({ data, tab, shouldExpand = false, dimensionImg = []}) {
    const specItems = useMemo(() => data.map(spec => spec.id), [data])
    const { toolTips } = useToolTips({ facets: specItems })
    const tableData = sortObjectArrayByKey(data, "sort_order")

    return(
        <ErrorBoundary>
            <Truncate
                className={clsx(DetailStyle.masonryBlock, (dimensionImg.length > 0) ? Style.gridSplit : "")}
                maxHeight={((dimensionImg.length > 0) || shouldExpand) ? "unset" : "400px"}
                shouldTruncate={((dimensionImg.length > 0) || shouldExpand) ? false :  true}
                buttonText={((dimensionImg.length > 0) || shouldExpand) ? "" :  "Read more"}
            >
                <article data-tracking={`pdp-item-${tab}`}>
                    <h3 className={clsx(utils.fs_4)}>
                        {tab === 'specs' ? 'Specifications' : tab}
                    </h3>

                    <table className={Style.block}>
                        <tbody>
                            {tableData.map((item, i) => {
                                return (
                                    <tr className={Style.row} key={`row_${i}`}>
                                        <td className={clsx(Style.cell)}>
                                            <div className={Style.cellContent}>
                                                <span dangerouslySetInnerHTML={{ __html: item?.label }} />

                                                {!!toolTips?.[item?.id] && (
                                                    <ToolTip text={toolTips?.[item?.id]} />
                                                    )}

                                            </div>
                                        </td>

                                        <td className={clsx(Style.cell)}>
                                            {item?.value && item?.value.toString().split("|").join(", ")}{item?.label && item?.label.includes("Temperature") && (/^[0-9,.]*$/.test(item?.value)) ? <>&deg;F</> : <>&nbsp;</>}

                                            <span>
                                                {item?.unit ?? ""}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })}

                            {dimensionImg.length > 0 && (
                                <tr>
                                    <td colSpan="2" className={clsx(utils.text_center)}>
                                        <Image
                                            title={dimensionImg[0].description}
                                            alt={dimensionImg[0].description}
                                            src={dimensionImg[0]?.image_url}
                                            width={482}
                                            height={531}
                                            className={Style.dimensionImg}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </article>
            </Truncate>
        </ErrorBoundary>
    )
}


SpecsTable.propTypes = {
    data: PropTypes.array
}


export default SpecsTable;