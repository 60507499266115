"use client"

import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { GrowthBook } from "@growthbook/growthbook-react";
import { useEffect } from "react";
import { parseCookies } from 'nookies'


const growthBook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: process.env.NEXT_PUBLIC_growthBook_clientKey,
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
        if ("gtag" in window) {
            window.gtag("event", "experiment_viewed", {
                event_category: "experiment",
                event_label: result.variationId,
                event_action: experiment.key,
                dimension1: experiment.key + ":" + result.variationId,
                non_interaction: true
            });
            
        } else {
            console.warn("no gtag for growthbook running tests");
        }
    },
})


function GrowthBookContext({ children }){
    useEffect(() => {
        growthBook.loadFeatures({ autoRefresh: true })

    }, [])


    

    useEffect(() => {
        // // Set user attributes for logged in users
        // const cookies = parseCookies();
        // const userData = cookies.TEAK_user;

        // if( userData ){
        //     growthBook.setAttributes({ ...userData });
        // }

        if ("gtag" in window) {
           gtag('get', process.env.NEXT_PUBLIC_Google_Analytics, 'client_id', (cid) => {
               growthBook.setAttributes({ id: cid }); 
            });
            
        } else {
            console.warn("no gtag for growthbook running tests");
        }


    }, [])



    return(
        <GrowthBookProvider growthbook={growthBook}>
            {children}
        </GrowthBookProvider>
    )
}


export default GrowthBookContext;