import { colorDisclaimerTxt } from "lib/Constants/colorDisclaimerTxt";
import Style from "./ColorDisclaimerText.module.scss";


function ColorDisclaimerText({ show = false }){
    return(
        <>
            {show && (
                <p className={Style.block}>
                    {colorDisclaimerTxt}
                </p>
            )}
        </>
    )
}

export default ColorDisclaimerText;