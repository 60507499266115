import { useMemo } from "react"
import { canRequestSwatchChecker } from '../../helpers/PIP.helpers';


export default function userBuyBoxViews({ custom_fields, options, variant_id, baseVariantId }) {

    const variantId = useMemo(() => {
        return parseInt(variant_id) !== parseInt(baseVariantId) ? baseVariantId : variant_id;
        
    }, [variant_id, baseVariantId])



    const isLongLeadTime = useMemo(() => {
        return (custom_fields?.lead_time + custom_fields?.lead_time_2).length > 70
    }, [
        custom_fields?.lead_time,
        custom_fields?.lead_time_2
    ])

    const showSwatchRequest = useMemo(() => {
        const canSwatchRequest = options?.length && canRequestSwatchChecker(options)
        return (canSwatchRequest && custom_fields?.swatch_request !== "No")
    }, [
        options,
        custom_fields.swatch_request,
    ])

    const isPriceHidden = useMemo(() => {
        return custom_fields?.is_price_hidden === "Yes" ||
        (custom_fields?.is_price_hidden && custom_fields?.is_price_hidden !== "No")

    }, [ custom_fields?.is_price_hidden ])


    return{
        isLongLeadTime,
        showSwatchRequest,
        isPriceHidden,
        variantId
    }
}