"use client"

import { useContext, useEffect } from "react";
import { ProductOptionContext } from 'components/applications/pip/contexts/ProductOptionContext';


function SetProductSetup({ productData }){
    const [ state, dispatch ] = useContext(ProductOptionContext);

    useEffect(() => {
        // build products
        let productObj = {};

        Object.keys(productData.product_sets).forEach(set => {
            productObj = {
                ...productObj,
                [set]: {
                    ...productData.product_sets[set]
                }
            }            
        })

        dispatch({ 
            type: "setSetProducts", 
            data:  productObj
        })
        
    }, [])


    return null
}

export default SetProductSetup;