import Style from './DisplayName.module.scss'

function HelpText({ children }){
    return(
        <div className={Style.optionSelections}>
            {children}
        </div>
    )
}

export default HelpText;