"use client"

import PropTypes from "prop-types";
import { useKeenSlider } from 'keen-slider/react'
import Image from "next/image";
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { blurImage } from 'lib/Constants/blurImage';
import { thumbnailPlugin, getSlides, getYoutubeImage, getYoutubeId } from "./ImageSlider.helpers";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import 'keen-slider/keen-slider.min.css'
import Style from './ImageSlider.module.scss';
import { IconChevronLeft } from "components/icons/IconChevronLeft";
import { IconChevronRight } from "components/icons/IconChevronRight";
import SliderImage from "./SliderImage";
import { cloudinaryProductImageLoader } from "thirdparty/Cloudinary/cloudinaryLoader";
import Flags from "components/display/Flags";
import GlobalHoverImage from "./GlobalHoverImage";
import { YouTubeEmbed } from "@next/third-parties/google";
import ImageZoom from "components/display/ImageZoom";
import ColorDisclaimerText from "../ColorDisclamerText";
import InnerImageZoom from "react-inner-image-zoom";




function ImageSlider({ 
    images, 
    videos, 
    customFields = {},
    setImages = [],
}){
    const [ slides, setSlides ] = useState([])
    const [ clickOver, showClickOver ] = useState(false)
    const [ currentSlide, setCurrentSlide ] = useState(0)
    const [ slideHasChanged, setSlideHasChanged ] = useState(false)

    const isPanelReady = useMemo(() => {
        return customFields?.panel_ready === "Yes" || 
        (customFields?.panel_ready && customFields?.panel_ready !== "No")

    }, [ customFields?.panel_ready ])


    const areAccessoriesIncluded = useMemo(() => {
        return customFields?.accessories_included === "Yes" || 
        (customFields?.accessories_included && customFields?.accessories_included !== "No")

    }, [ customFields?.accessories_included ])




    const [ sliderRef, instanceRef ] = useKeenSlider(
        {
            initial: 0,
            slides: {
                perView: 1,
                spacing: 10,
            },
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel)
            },
            animationStarted(slider) {
                setSlideHasChanged(slider)
            }
        }
    )

    const [ thumbnailRef, thumbInstanceRef ] = useKeenSlider(
        {
            initial: 0,
            slides: {
                perView: 4,
                spacing: 10,
            },
            breakpoints: {
                '(max-width: 800px)': {
                    slides: {
                        perView: 3
                    }
                },
                '(max-width: 500px)': {
                    slides: {
                        perView: 2
                    }
                },
            },
        },
        [
            thumbnailPlugin(instanceRef)
        ]
    )



    useEffect(() => {
        const slides = getSlides({ images, videos, setImages })

        if( Array.isArray(slides) ){
            slides.forEach(slide => {
                slide.video = getYoutubeImage(slide);

                slide.imageUrl = slide?.file_name ? cloudinaryProductImageLoader({
                    src: slide?.file_name,
                    quality: "auto",
                    width: 800
                }) : slide.image_url

                slide.rawImageUrl = cloudinaryProductImageLoader({
                    src: slide?.raw_file_name ?? "",
                    quality: "auto",
                    width: 2000
                })
            })
        }

        setSlides(slides)

    }, [ images, videos, setImages ])







    return(
        <div className={clsx(Style.block, Style.flex)} onMouseEnter={() => showClickOver(true)}>
            <div className={Style.mainSlider}>

            <GlobalHoverImage {...{ slideHasChanged }} />
            
            {slides.length > 1 ? (
                <section ref={sliderRef} className="keen-slider">
                    {slides.map((slide, i) => {
                        const isLoaded = i === currentSlide

                        return(
                            <div key={`slide_${i}`} className="keen-slider__slide">
                                {slide?.image_url && (
                                    <InnerImageZoom 
                                        imgAttributes={{
                                            alt: slide?.description ?? `Slide ${i}`
                                        }}
                                        zoomSrc={isLoaded && (slide?.raw_file_name && slide.rawImageUrl)}
                                        zoomScale={3}
                                        src={isLoaded ? slide.imageUrl : blurImage}
                                        width={800}
                                        height={800}
                                        hasSpacer={true}
                                        className={Style.mainImage}
                                    />

                                    // <ImageZoom 
                                    //     alt={slide?.description ?? `Slide ${i}`} 
                                    //     className={clsx(Style.mainImage)}
                                    //     src={isLoaded ? slide.imageUrl : blurImage}
                                    //     width={800}
                                    //     height={800}
                                    //     zoomSrc={isLoaded && (slide?.raw_file_name && slide.rawImageUrl)}
                                    // />
                                )}
                                    
                                {slide.video && (
                                    <div className={Style.videoCntr}>
                                        <YouTubeEmbed 
                                            videoid={getYoutubeId(slide)}
                                            height="400"
                                            params="controls=0"
                                        />
                                    </div>
                                )}

                                {(i === 0 && ( isPanelReady || areAccessoriesIncluded )) && (
                                    <div className={Style.badgeCntr}>
                                        <Flags flags={{ 
                                            panel_ready: isPanelReady, 
                                            accessories_included: areAccessoriesIncluded
                                        }} />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </section>
            ):(
                <InnerImageZoom 
                    imgAttributes={{
                        alt: slides[0]?.description ?? `Slide`
                    }}
                    zoomSrc={slides[0]?.raw_file_name && slides[0]?.rawImageUrl}
                    zoomScale={3}
                    src={slides[0]?.imageUrl ?? blurImage}
                    width={800}
                    height={800}
                    hasSpacer={true}
                    className={Style.mainImage}
                />


                // <ImageZoom 
                //     alt={slides[0]?.description ?? `Slide`} 
                //     className={clsx(Style.mainImage)}
                //     src={slides[0]?.imageUrl ?? blurImage}
                //     width={800}
                //     height={800}
                //     zoomSrc={slides[0]?.raw_file_name && slides[0]?.rawImageUrl}
                // />
            )}
                
            </div>



            {slides.length > 1 && (
                <div className={Style.thumbnailCntr}>
                    <button 
                        type="button" 
                        className={Style.navigateBtn}
                        onClick={(e) => e.stopPropagation() || instanceRef?.current?.prev()}
                        disabled={currentSlide === 0}
                    >
                        <IconChevronLeft width="30" height="30" />
                    </button>

                    <div className="keen-slider" ref={thumbnailRef}>
                        {slides.map((slide, i) => {
                             const isLoaded = i === currentSlide

                            return(
                                <figure key={`slide_${i}`} className="keen-slider__slide">
                                    <button 
                                        type='button' 
                                        className={clsx(Style.thumbButton)} 
                                        name={`go to ${i}`} 
                                    >
                                    {slide?.image_url && (
                                        <SliderImage 
                                            {...slide} 
                                            isLoaded
                                            width={100}
                                            height={100}
                                        />
                                    )}
                                        
                                    {slide.video && (
                                        <>
                                            <div className={Style.thumbOverlay}>
                                                <div className={Style.videoPlayArrow} />
                                            </div>

                                            <Image 
                                                src={isLoaded ? slide.video : blurImage}
                                                alt={`Video ${i}`} 
                                                placeholder="blur"
                                                blurDataURL={blurImage}
                                                width={133}
                                                height={100}
                                            />
                                        </>
                                    )}
                                    </button>
                                </figure>
                            )
                        })}
                    </div>

                    <button 
                        type="button" 
                        className={Style.navigateBtn}
                        onClick={(e) => e.stopPropagation() || instanceRef?.current?.next()}
                        disabled={(currentSlide + 1) === instanceRef?.current?.track?.details?.slides.length}
                    >
                        <IconChevronRight width="30" height="30" />
                    </button>
                </div>
            )}


            <ColorDisclaimerText show={customFields?.color_disclaimer} />
           
        </div>
    )
}


ImageSlider.propTypes = {
    images: PropTypes.array,
    videos: PropTypes.array
}

export default ImageSlider;