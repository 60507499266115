"use client"

import PropTypes from "prop-types";
import Breadcrumb from 'components/navigation/Breadcrumbs';
import utils from 'styles/globals/utils.module.scss';
import { usePathname } from "next/navigation";

 
function TailoredBreadcrumb({ 
    gridStart = 2, 
    gridEnd = 10,
    listClassName,
    categoryUrl = null
}){
    const urlPath = usePathname()

    return(   
        <Breadcrumb 
            urlPath={categoryUrl ?? urlPath} 
            {...{ listClassName, gridEnd, gridStart }} 
            className={utils.p_0}
        />
    )
}


TailoredBreadcrumb.propTypes = {
    categoryId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    urlPath: PropTypes.string,
    gridEnd: PropTypes.number,
    gridStart: PropTypes.number,
    listClassName: PropTypes.string
}


export default TailoredBreadcrumb;