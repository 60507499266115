/* eslint-disable react-hooks/exhaustive-deps */
import CloseBtn from 'components/forms/CloseButton';
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import Image from "next/image";
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import ReviewStars from 'components/product/ReviewStars';
import YotpoService from 'thirdparty/Yotpo/Yotpo.service';

import utils from 'styles/globals/utils.module.scss';
import Style from './ProductUGCModal.module.scss';



function ProductUGCModal({ images, index = 0, closeModal }){
    const [ largeImg, setLargeImg ] = useState(images[index])
    const [ stars, setStars ]= useState(5)

    const yotpoService = new YotpoService()

    useEffect(() => {
        yotpoService.getReviewById(largeImg.review_id).then(data => {
            setStars(data)
        })

    }, [largeImg])


    return(
        <dialog className={Style.block} open>
            <CloseBtn 
                shouldClose={() => closeModal(false)}
                className={Style.close} 
            />

            <Grid>
                <GridColumn start={1} end={3} className={Style.thumbCntr}>
                    <ul className={Style.photoList}>
                        {images.map((img) => {
                            return(
                                <li key={img.image_id} className={Style.photoItem}>
                                    <button 
                                        type="button" 
                                        className={clsx(Style.button, largeImg.image_id === img.image_id && Style.activeButton)}
                                        onClick={() => setLargeImg(img)}
                                    >
                                        <Image
                                            className={Style.photo}
                                            width={200}
                                            height={200}
                                            placeholder="blur"
                                            blurDataURL={img.thumbnail}
                                            src={img.original_image_url}
                                            alt={img.post.title}
                                        />
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </GridColumn>

            

                <GridColumn start={3} end={10} className={Style.largePhotoCntr}>
                    <header className={Style.header}>
                        <h4 className={clsx(Style.heading, utils.fs_5)}>
                            <span>{largeImg.post.title}</span> 
                            <span className={Style.headerMeta}>
                                <ReviewStars average_score={stars?.score} /> - <small className={Style.user}>{stars?.user?.display_name} {stars.verified_buyer && <em>(Verified Buyer)</em>}</small>
                            </span>
                        </h4>
                        <p className={Style.text}>
                            {largeImg.post.content}
                        </p>
                    </header>
                    
                    <Image
                        className={Style.photo}
                        src={largeImg.original_image_url}
                        alt={largeImg.post.title}
                        width={700}
                        height={550}
                    />
                </GridColumn>
            </Grid>

        </dialog>
    )
}

export default ProductUGCModal;