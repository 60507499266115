"use client"

import DisplayName from "components/applications/pip/components/Options/DisplayName";
import IconExtend from "thirdparty/Extend/icons/IconExtend";
import Style from "./OfferOptions.module.scss";
import ExtendHelpText from "../Plans/ExtendHelpText";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ProductOptionContext } from "components/applications/pip/contexts/ProductOptionContext";
import { OfferContext } from "thirdparty/Extend/context/OfferContext";
import { useExtendCart } from "thirdparty/Extend/hooks/useExtendCart";
import Plans from "../Plans";
import { useGetExtendPlans } from "thirdparty/Extend/hooks/useGetExtendPlans";
import PlanOptionsPreloader from "components/applications/pip/components/Options/OptionsPreloader/PlanOptionsPreloader";
import useAtcCallbacks from "components/applications/cart/hooks/useAtcCallbacks";


function OfferOptions({
    sku, 
    category, 
    qty,
    name,
    context,
    isOpen = false,
    serverPlans = null,
    leadToken = null
}){
    const [ productOptionState, _ ] = useContext(ProductOptionContext);
    const [ state, dispatch ] = useContext(OfferContext);
    const extendCart = useExtendCart()
    const [ hideLoader, setHideLoader ] = useState(false)
    let loaderTimeout = useRef(null)

    const fetchedPlans = useGetExtendPlans({ 
        sku, 
        category, 
        name, 
        context: { 
            qty,
            price: Math.round(context.price/qty),
        }
    })

    const plans = useMemo(() => serverPlans ?? fetchedPlans, [ fetchedPlans, serverPlans ])


    useEffect(() => {
        // when a product price is updated and a product is selected update the product in the pre-cart
        const haveUpdatedPlan = plans?.list?.find(plan => plan?.id === state?.selected?.id)

        if( haveUpdatedPlan ){
            const { id, price, contract } = haveUpdatedPlan;
            const planSku = leadToken ? `${id};xtd;LEAD::${leadToken}::MAX::${qty}` : `${id};xtd;${sku}`

            dispatch({
                type: "setPlan",
                data: {
                    id,
                    name: `Extend Protection Plan - ${contract?.termLength/12} years`,
                    price: price/100,
                    sku: planSku,
                }
            })
        }

        // run a timeout for the loader that prevents CLS
        if(Array.isArray(plans?.list) && plans?.list?.length > 0 && plans?.qty){
            // destroy the timeout
            clearTimeout(loaderTimeout.current)

        }else{
            loaderTimeout.current = setTimeout(() => {
                setHideLoader(true)
            }, 1500)
        }

    }, [ plans?.list ])




    /**
     * When the product is added to the cart, we need to add the plan to the cart
     * synchronously to avoid a race condition on the confirmation 
     * page redirect in trigger confirmation
     */

    const atcCallback = useAtcCallbacks({
        buyNow: productOptionState?.atcStatus?.buyNow,
        prodCat: productOptionState?.atcStatus?.prodCat,
        contextState: productOptionState?.atcStatus?.contextState,
        redirectToCart: productOptionState?.atcStatus?.redirectToCart,
    })


    useEffect(() => {
        if( productOptionState?.atcStatus?.status === "done" ){
            extendCart.add({
                planSku: sku,
                name: state?.selected[sku]?.name,
                sku: state?.selected[sku]?.sku,
                price: state?.selected[sku]?.price,
                qty

            }, (response) => {                
                atcCallback.triggerConfirmation({
                    productAdded: {
                        ...productOptionState?.atcStatus?.productAdded,
                        buyNow: productOptionState?.atcStatus?.buyNow
                    },
                    cartData: response?.data
                })
                
            })
        }

    }, [
        productOptionState?.atcStatus?.status
    ])





    return(
        <>
            {(Array.isArray(plans?.list) && plans?.list?.length > 0 && plans?.qty) ? (
                <details className={Style.block} open={productOptionState?.readyToAddToCart || isOpen}>
                    <DisplayName 
                        name="Select Protection Plan"
                        isOpen={productOptionState?.readyToAddToCart || isOpen}
                        step={<IconExtend width="25" height="25" fill="#ffffff" />}
                        isStepCallout
                        hasError={false}
                        length={4}
                        stepClassName={Style.step}
                        helpText={(
                            <ExtendHelpText 
                                text={plans?.marketing?.contractPurchaseEmail} 
                                cta={plans?.list?.[0]?.url}
                            />
                        )}
                    />

                    <Plans 
                        isDisabled={!productOptionState?.readyToAddToCart}
                        {...{ 
                            sku, 
                            leadToken,
                            context: {
                                qty,
                                price: context.price,
                            },
                            plans
                        }} 
                    />
                </details>
            ):(
                <>
                    {!hideLoader && (
                        <PlanOptionsPreloader />
                    )}
                </>
            )}
        </>
    )
}

export default OfferOptions;