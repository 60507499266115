"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import Style from './ProductReviews.module.scss'
import utils from 'styles/globals/utils.module.scss';

import PropTypes from "prop-types";
import { useGetProductReviews } from 'thirdparty/Yotpo/YotpoContext';
import clsx from "clsx";
import { useCallback, useState } from 'react';
import dynamic from 'next/dynamic'

const ReviewSection = dynamic(() => import('./ReviewSection'))
const NoReviews = dynamic(() => import('./NoReviews'))
const RatingsListItem = dynamic(() => import('./RatingsListItem'))
const Divider = dynamic(() => import('components/display/Divider')) 
const Pagination = dynamic(() => import('components/navigation/Pagination')) 


function ProductReviews({ productId, returnUrl }){
    const [ page, setPage ] = useState(1)
    const [sort, setSort] = useState("");
    
    const reviews = useGetProductReviews(productId, { page, sort })

    const pageReviews = useCallback((e, num) => {
        if( Object.keys(e).length ){
            e.preventDefault()
            setPage(num)
            location.href = `#productReviews`
        }
       
    }, [])


    
    return (
        <section className={Style.block} id="productReviews">
            <header className={Style.heading}>
                <h2 className={clsx(utils.fs_2)}>
                    Ratings & Reviews
                </h2>

                {!reviews.items?.length ? (
                    <NoReviews productId={productId} returnUrl={returnUrl} />
                ):null}
            </header>


            {(Array.isArray(reviews.items) && reviews.items?.length) ?                        
                <ReviewSection 
                    {...reviews?.data}
                    productId={productId}
                    returnUrl={returnUrl}
                    reviewItems={reviews.items}
                    avg_score={reviews?.data?.average_score}
                    star_dist={reviews?.data?.star_distribution}
                    sortLabel={sort}
                    setSortOrder={setSort}
                />                           
            :null}


            {reviews?.data?.total_review > 5 && (
                <>
                    <div className={clsx(utils.d_flex, utils.justify_content_center, utils.mt_5)} data-tracking="reviewsPagination">
                        <Pagination 
                            page={page} 
                            onChange={(e, num) => pageReviews(e, num)}
                            count={Math.round(reviews.data.total_review / 5)}
                        />
                    </div>

                    <Divider className={utils.my_5} />
                </>
            )}
        </section>
    );
}


ProductReviews.propTypes = {
    productId: PropTypes.number,
    returnUrl: PropTypes.string
}


export default ProductReviews;