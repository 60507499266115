"use client"

import clsx from "clsx";
import { useGetProductUGC } from "thirdparty/Yotpo/YotpoContext";
import Image from "next/image";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ProductUGCModal from "./ProductUGCModal";
import utils from 'styles/globals/utils.module.scss';
import Style from './ProductUGC.module.scss';
import Divider from "components/display/Divider";


const parseProductUGCbyID = (ugcImages, productId) => {
    return ugcImages?.filter(ugc => ugc.tagged_products[0] === productId.toString());
}


function ProductUGC({ productName, productId, returnUrl }){
    const [ modalIndex, setModalIndex ] = useState(0)
    const [ openModal, setOpenModal ] = useState(false)
    const userImages = useGetProductUGC();

    const images = userImages && parseProductUGCbyID(userImages?.data?.images, productId);
    

    const openUGCModal = (index) => {
        setModalIndex(index)
        setOpenModal(true)
    }


    return(
        <>
            {images?.length ? (
                <>
                    <section className={Style.block}>
                        <h2 className={clsx(utils.fs_2, Style.heading)}>
                            Customer Photos
                        </h2>

                        <ul className={Style.photoList}>
                            {images.map((img, index) => {
                                return(
                                    <li key={img.image_id} className={Style.photoItem}>
                                        <button 
                                            type="button" 
                                            className={Style.button}
                                            onClick={() => openUGCModal(index)}
                                        >
                                            <Image
                                                className={Style.photo}
                                                width={200}
                                                height={200}
                                                placeholder="blur"
                                                blurDataURL={img.thumbnail}
                                                src={img.original_image_url}
                                                alt={img.post.title}
                                            />
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </section>

                    <Divider isSpacer className={utils.my_8} />
                </>
                ):
                  null  
                // <div className={Style.beTheFirst}>
                //     <p>Be the first to show how the <em>{productName}</em> looks in your space!</p>
                    
                //     <p className={utils.mb_0}>
                //         <Button 
                //             size="md" 
                //             href={`/p/product-review?id=${productId}&return=${returnUrl}`} 
                //             type="link"
                //         >
                //             <IconCamera className={Style.cameraIcon} />
                //             Submit photo now
                //         </Button>
                //     </p>
                // </div>

            }

            {openModal && (
                <ProductUGCModal 
                    images={images} 
                    index={modalIndex}
                    closeModal={() => setOpenModal(false)}
                />
            )}
       </>
    )
}


ProductUGC.propTypes = {
    productId: PropTypes.number,
    productName: PropTypes.string
};


export default React.memo(ProductUGC);