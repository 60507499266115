import dynamic from 'next/dynamic'
import Divider from "components/display/Divider";
import clsx from 'clsx';
import Style from './Shipping.module.scss';
import { useMemo } from 'react';
import NoReturns from './NoReturns';
import utils from 'styles/globals/utils.module.scss';


const WhiteGlove = dynamic(() => import('components/applications/pip/components/Shipping/WhiteGlove'))
const HeavyFreight = dynamic(() => import('components/applications/pip/components/Shipping/HeavyFreight'))
const LeadTime = dynamic(() => import("components/applications/cart/components/CartItem/LeadTime"))
const LowInventory = dynamic(() => import('components/applications/pip/components/Shipping/LowInventory'))


function Shipping({ customFields, isLongLeadTime }){
    const isHeavyFreight = useMemo(() => {
        return customFields?.heavy_freight === "Yes" || (customFields?.heavy_freight && customFields?.heavy_freight !== "No")
    }, [ customFields?.heavy_freight ])


    const isWhiteGloveEligible = useMemo(() => {
        return customFields?.white_glove_eligible === "Yes" || (customFields?.white_glove_eligible && customFields?.white_glove_eligible !== "No")
    }, [ customFields?.white_glove_eligible ])


    const isFinalSale = useMemo(() => {
        return customFields?.final_sale === "Yes" || (customFields?.final_sale && customFields?.final_sale !== "No")
    }, [ customFields?.final_sale ])


    const hasLowInventory = useMemo(() => {
        return customFields?.low_inventory === "Yes" || (customFields?.low_inventory && customFields?.low_inventory !== "No")

    }, [ customFields?.low_inventory ])


    

    return(
        <div className={clsx(Style.block, (isHeavyFreight|| isLongLeadTime) && Style.wrap )}>
            {isFinalSale && (
                <>
                    <div className={Style.shipType}>
                        <NoReturns />
                    </div>

                    <Divider className={utils.mb_2} />
                </>
            )}


            <div className={clsx(Style.shipType, isLongLeadTime && Style.longLeadTime )}>
                <LeadTime 
                    leadTime={customFields?.lead_time}
                    leadTime2={customFields?.lead_time_2}
                    className={Style.leadTime}
                />
            </div>


            {isWhiteGloveEligible && (
                <>
                    <Divider />
                    
                    <div className={Style.shipType}>
                        <WhiteGlove />
                    </div>
                </>
            )}


            {isHeavyFreight && (
                <>
                    <Divider />

                    <div className={clsx(Style.shipType, Style.heavyFreight)}>
                        <HeavyFreight />
                    </div>
                </>
            )}


            {hasLowInventory && (
                <>
                    <Divider />

                    <div className={clsx(Style.shipType, Style.lowInventory)}>
                        <LowInventory />
                    </div>
                </>
            )}

        </div>
    )
}


export default Shipping;