import Image from "next/image";
import Style from './DisplayName.module.scss'



function SelectedDisplay({ selectedOption, blurImage }){
    return(
        <>
            {(!Array.isArray(selectedOption) && selectedOption?.title) ? (
                <div className={Style.optionSelections}>
                    <span className={Style.selected}>
                        {selectedOption?.image && (
                            <Image 
                                src={selectedOption?.image} 
                                width={15} 
                                height={15} 
                                alt={selectedOption?.title} 
                                className={Style.image} 
                                placeholder="blur"
                                blurDataURL={blurImage}
                            />
                        )} {selectedOption.title}
                    </span>
                </div>

            ):(
                <>
                    {Array.isArray(selectedOption) && (
                        <div className={Style.optionSelections}>
                            <span className={Style.selected}>
                                {selectedOption?.[0]?.image && (
                                    <Image 
                                        src={selectedOption?.[0]?.image} 
                                        width={15} 
                                        height={15} 
                                        alt={selectedOption?.[0]?.title} 
                                        className={Style.image} 
                                        placeholder="blur"
                                        blurDataURL={blurImage}
                                    />
                                )} 
                                
                                {selectedOption.map(opt => opt.title).join(", ")}
                            </span>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default SelectedDisplay;