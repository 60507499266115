import { useContext, useEffect } from "react"
import { ProductOptionContext } from "../../contexts/ProductOptionContext"
import Image from "next/image"
import Style from './ImageSlider.module.scss';
import { blurImage } from 'lib/Constants/blurImage';

function GlobalHoverImage({ slideHasChanged }){
    const [ global, dispatch ] = useContext(ProductOptionContext)

    useEffect(() => {
        dispatch({
            type: "setHoverImage",
            data: null
        })

    }, [ slideHasChanged?.track?.details?.abs ])


    return(
        <>
            {(global.hoverImage) && (
                <Image 
                    src={global.hoverImage} 
                    width={800} 
                    height={800} 
                    alt="Image hover Alternative" 
                    className={Style.hoverImage}
                    blurDataURL={blurImage}
                    placeholder="blur"
                />
            )}
        </>
    )
}


export default GlobalHoverImage