"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { ProductOptionContext } from 'components/applications/pip/contexts/ProductOptionContext';
import parseOptionLabel from 'components/applications/pip/helpers/parseOptionLabel.helper';
import { LocalStorage } from 'services/LocalStorage.service';


function OptionSetReceiver({ productId, option, variantId }){
    const [ state, dispatch ] = useContext(ProductOptionContext);


    // Watch and Match the Globally set option for all products that have this option name
    useEffect(() => {
        if( state.setReceiverControl?.key === option?.display_name && Array.isArray(option.values) ){
            const parsedOptions = option.values.map(opt => {
                const parsed = parseOptionLabel(opt.label)

                return {
                    ...parsed,
                    ...opt
                }
            })


            const matchedOption = parsedOptions.find(val => {
                if( val.text.toLowerCase() === state.setReceiverControl.title.toLowerCase() ){ 
                    return val;
                }
            })


            // If the option is a full product image, set the image in the cart
            if( matchedOption.swatch_type === "full_product" ){
                const imageCart = LocalStorage.getStorage("TEAK_image_cart")

                LocalStorage.setStorage("TEAK_image_cart", { 
                    ...imageCart,
                    [productId]: matchedOption.image 
                }, "local")
            }


            if( matchedOption ){
                dispatch({
                    type: "updateSets",
                    data: {
                        key: option?.display_name,
                        title: matchedOption?.text,
                        attribute: parseInt(option?.option_group_id),
                        attributeValue: parseInt(matchedOption?.option_id),
                        price: (parseInt(matchedOption?.priceAdjustNumeric) * parseInt(state.sets?.products?.[productId]?.quantity)) ?? 0 ,
                        product_id: parseInt(productId),
                        variant_id: parseInt(variantId)
                    }
                })
            }
        }

    }, [ state.setReceiverControl ])



    // Match the Auto Chosen No Thanks Options
    useEffect(() => {
        const matchedNoThanks = state.autoNoThanks.filter(noThx => noThx.product_id === productId)

        if( matchedNoThanks ){
            matchedNoThanks.forEach(match => {
                const noThanks = match.values.find(val => val?.label.includes("No "))
                const selected = parseOptionLabel(noThanks?.label)

                dispatch({
                    type: "updateSets",
                    data: {
                        key: match?.display_name,
                        title: selected?.text,
                        attribute: parseInt(match?.option_group_id),
                        attributeValue: parseInt(noThanks?.option_id),
                        price: selected?.priceAdjustNumeric ?? 0,
                        product_id: parseInt(productId),
                        variant_id: parseInt(variantId)
                    }
                })
            })
        }

    }, [
        state.autoNoThanks,
        state.setReceiverControl
    ])


    return null;
}


export default OptionSetReceiver;
