/* eslint-disable react-hooks/exhaustive-deps */

"use client"

import PropTypes from 'prop-types';
import { GetAd } from 'contexts/AdContext';
import Loader from 'components/feedback/Loader';
import clsx from 'clsx';
import Link from 'next/link';
import { Suspense, useEffect, useState } from 'react';

import utils from 'styles/globals/utils.module.scss';
import Picture from 'components/display/Picture';


function ImageAd({ categoryUri, slug, className, target }){
    const [ ad, setAd ] = useState(null)

    useEffect(() => {
        GetAd(categoryUri, slug).then((response) => {
            if( response?.[0] ){
                setAd(response[0])
            }
        })
        .catch(error => {
            console.log(error)
        })

    }, [])

    // we are only picking up the first Ad by date or that is not expired
    // const adData = ads.data?.entries?.[0];

    return(
        <Suspense fallback={
            <div className={clsx(utils.w_100, utils.my_3)}>
                <Loader />
            </div> 
        }>
            {ad && (
                <Link 
                    href={ad?.adUrl}
                    className={clsx(utils.w_100, utils.my_3, utils.d_block)}
                    target={target}
                >
                    <Picture 
                        width={ad?.image?.[0].width}
                        height={ad?.image?.[0].height} 
                        alt={ad?.title}
                        mobile={ad?.mobileImage?.[0]?.url}
                        desktop={ad?.image?.[0].url}
                        className={className}
                    />
                </Link>
            )}
        </Suspense>
    )
}


ImageAd.propTypes = {
    className: PropTypes.string,
    categoryUri: PropTypes.string.isRequired,
    slug: PropTypes.string,
    target: PropTypes.string
}


export default ImageAd;

