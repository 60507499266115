import Link from "next/link";
import Style from './Variants.module.scss';
import Image from "next/image";
import { cloudinaryProductImageLoader } from "thirdparty/Cloudinary/cloudinaryLoader";
import clsx from "clsx";

import { sendGTMEvent } from "@next/third-parties/google";


let timeout = null,
    hoverTimeout = null;

function Control({ 
    product_uri = null, 
    variation_value, 
    shape, 
    thumbnail_image, 
    variation_label, 
    product_image,
    active = false,
    disabled,
    onHover = () => null 
}){

    
    function toggle({ image, text, uri}){
        if( image || text ){
            const src = image ? image.split("/product_images/")[1] : null

            if(src === null) return;

            const productImage = cloudinaryProductImageLoader({
                width: 500,
                height: 500,
                src,
            })

            onHover({ image: productImage, text });
            
            hoverTimeout = setTimeout(() => {
                sendGTMEvent({ event: 'variant_hovered', varURLHovered: process.env.NEXT_PUBLIC_siteUrl + "/" + uri });
            }, 1000)

            clearTimeout(timeout)

            return;

        }else{
            timeout = setTimeout(() => {
                onHover({ image, text });
            }, 200)
            clearTimeout(hoverTimeout)

        }
    }   


    return(
        <li className={Style.item}>
            {disabled ? (
                <span className={clsx(Style[shape], Style.disabledItem)}>
                    <ControlContent 
                        {...{ thumbnail_image, variation_label, variation_value, active, product_image }}
                    />
                </span>
            ):(
                <Link 
                    href={product_uri ? `/${product_uri}` : "#"} 
                    className={clsx(Style[shape], active && Style.active)} 
                    title={variation_label+": "+variation_value}
                    onMouseEnter={() => toggle({ image: product_image, text: variation_value, uri:product_uri })}
                    onMouseLeave={() => toggle({ image: null, text: null, uri:null })}
                >
                    <ControlContent 
                        {...{ thumbnail_image, variation_label, variation_value, active, product_image }}
                    />
                </Link>
            )}
        </li>
    )
}





function ControlContent({ 
    thumbnail_image, 
    variation_label, 
    variation_value, 
    active = false,
    product_image
}){
    return(
        <>
            {thumbnail_image  ? (
                <Image 
                    src={thumbnail_image ?? product_image} 
                    width={40} 
                    height={40} 
                    alt={variation_label+": "+variation_value} 
                    className={clsx(Style.image, active && Style.active)} 
                />
            ):(
                <>
                    {variation_value}
                </>
            )}
        </>
    )
}



export default Control;

