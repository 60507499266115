import { blurImage } from 'lib/Constants/blurImage';
import Style from './ImageSlider.module.scss';



export function getSlides({ images, videos, setImages = null }){
    const defaultSlide = { name: "default image", image_url: blurImage, description: "default image", zoom_url: blurImage 
}
    const parsedImages =  ( (images && typeof images === "string") ? JSON.parse(images) : images ) ?? [ defaultSlide ];
    const parsedVideos = ( (videos && typeof videos === "string") ? JSON.parse(videos) : videos ) ?? "";
    
    const slidesImgVideo = [
        ...parsedImages, 
        ...parsedVideos
    ];

    // if we have product set images, add them in there too
    // const productSetImg = setImages && setImages.map(img => {
    //     return{
    //         image_url: img,
    //         description: "product set image",
    //         zoom_url: img
    //     }
    // })

    return  [ ...slidesImgVideo ]
}



// should probably update the api data to just have the youtube video ID
export const getYoutubeId = (video) => {
    const key = Object.keys(video)[0];
    const id = video[key].includes("https://youtu.be") ? video[key].split("https://youtu.be/")[1] : video[key].split("v=")[1];

    return id;
}


export const getYoutubeUrl = (videoObj) => {
    const id = getYoutubeId(videoObj)
    return id ? `https://www.youtube.com/embed/${id}` : null;

}


export const getYoutubeImage = (slide) => {
    const id = getYoutubeId(slide);

    return id ? `https://img.youtube.com/vi/${id}/0.jpg` : null
}



export function thumbnailPlugin(mainRef){
    return ( slider ) => {
        function removeActive() {
            slider.slides.forEach((slide) => {
                slide.classList.remove(Style.active)
            })
        }

        function addActive(idx) {
            slider.slides[idx].classList.add(Style.active)
        }

        function addClickEvents() {
            slider.slides.forEach((slide, idx) => {
                slide.addEventListener("click", () => {
                    if (mainRef.current){
                        mainRef.current.moveToIdx(idx)
                    }
                })
            })
        }


        slider.on("created", () => {
            if (!mainRef.current){ return }

            addActive(slider.track.details.rel)
            addClickEvents()

            mainRef.current.on("animationStarted", (main) => {
                const next = main.animator.targetIdx || 0

                removeActive()
                addActive(main.track.absToRel(next))

                slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
            })
        })

    }
}



