import clsx from 'clsx';
import Style from './DisplayName.module.scss'



function SetExtraDisplay({ products, productSetLength }){
    return(
        <span className={Style.extras}>        
            {(productSetLength !== products.length) ? (
                <small className={Style.extrasName}>
                    <span className={Style.extrasStandard}>
                        For the:
                    </span> 

                    {products.map((product, i) => `${product.name}${i+1 !== products.length ? ", " : ""}`)}
                </small>
            ):(
                <small className={clsx(Style.extrasName, Style.extrasStandard)}>
                    For all pieces in this set.
                </small>
            )}
        </span>
    )
}

export default SetExtraDisplay;