

// get cms saved ads

export default function GetAdvertisement( categoryUri, slug ){
    return `query GetAds {
        entries(section: "advertisements", orderBy: "postDate DESC", limit: 1, relatedToCategories: {uri: "${categoryUri}"} ${slug ? `, slug: "${slug}"` : ""}) {
        ... on advertisements_Entry {
                id
                title
                expiryDate
                dateCreated
                adUrl
                image {
                    filename
                    width
                    height
                    url
                }
            }
        }
    }`
}