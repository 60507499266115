"use client"

import Control from './Control';
import { useContext, useMemo, useState } from 'react';
import Style from './Variants.module.scss';
import clsx from 'clsx';
import { ProductOptionContext } from '../../contexts/ProductOptionContext';


function VariantGroup({ 
    currentVariant, 
    optionKey, 
    option, 
    totalVariants,
    disabled = []
}){
    const [ hoveredVariant, setHoveredVariant ] = useState(null);
    const [ _, dispatch ] = useContext(ProductOptionContext)

    function onHover({ image, text }){
        setHoveredVariant(text)

        dispatch({
            type: "setHoverImage",
            data: image
        })
    }


    const sortedOption = useMemo(() => {
        // if the first character is a number, sort by number
        if( !isNaN(option[0]?.variation_value[0]) ){
            // sort a string that starts with numbers with decimals and then letters: parseFloat because parseFloat don't care about the letters
            return option.sort((a, b) => parseFloat(a.variation_value) - parseFloat(b.variation_value))
        }else{
            return option;
        }

    }, [ option ])



    return(
        <article className={clsx(Style.group, (totalVariants === 1) ? Style.fullGroup : "")}>
            <header className={Style.heading}>
                <strong>{optionKey}</strong>: {hoveredVariant ?? currentVariant?.[optionKey]}
            </header>

            <menu className={Style.list}>
                {sortedOption.map((variant, index) => {
                    const { variation_label, disabled, thumbnail_image, file_name, variation_value, product_uri, product_image } = variant ?? null;

                    return(
                        <Control 
                            key={variation_value +"_"+ index}
                            onHover={onHover}
                            active={currentVariant[optionKey] === variation_value}
                            {...{ 
                                thumbnail_image, 
                                variation_label, 
                                shape: thumbnail_image ? "circle" : "pill", 
                                variation_value, 
                                product_image,
                                product_uri,
                                disabled
                            }}
                        />
                    )
                })}
            </menu>
        </article>
    )
}

export default VariantGroup;

