"use client"

import dynamic from 'next/dynamic'
import { lazy, useContext, useRef, useState } from "react";
import clsx from 'clsx';
import AddToCartButton from "components/product/AddToCartButton";
import Link from 'next/link'
import Button from 'components/forms/Button';
import Divider from "components/display/Divider";
import { IconChatBubble } from "components/icons/IconChatBubble";
import { IconShoppingBag } from "components/icons/IconShoppingBag";
import { IconWarranty } from "components/icons/IconWarranty";
import Shipping from "components/applications/pip/components/Shipping";
import ErrorBoundary from 'services/ErrorBoundary';
import { triggerChat } from 'thirdparty/Zendesk/Zendesk.service';
import Style from './BuyBox.module.scss';
import utils from 'styles/globals/utils.module.scss';
import { ProductOptionContext } from '../../contexts/ProductOptionContext';
import OrDivider from 'components/display/Divider/OrDivider';
import PageModal from 'components/blocks/PageModal'
import Styles from 'components/blocks/PageModal/PageModal.module.scss'
import { OfferContextProvider } from 'thirdparty/Extend/context/OfferContext';
import OfferOptions from 'thirdparty/Extend/components/OfferOptions';
import userBuyBoxViews from './userBuyBoxViews';


const Options = lazy(() => import('../Options'))


/** ----------------
 * Dynamic Imports
 ----------------- */
const DynamicPrice = dynamic(() => import('components/applications/pip/components/DynamicPrice'))
const QuantityButton = dynamic(() => import('components/product/QuantityButton'))
const Addons = dynamic(() => import('../Options/Addons'))
const SetItemsList = dynamic(() => import('../sets/SetItemsList/SetItemsList'))
const SwatchOrderButton = dynamic(() => import("components/applications/pip/components/Options/SwatchOrderButton"))


function BuyBox({ 
    isMobile, 
    productData, 
    isTrade, 
    userId = null,
    bcOptions = [],
    baseVariantId
}){

    const [ qty, setQty ] = useState(1);
    const [ state, dispatch ] = useContext(ProductOptionContext)
    const optionsRef = useRef()
    //const callRailPh = useCallTracking();

    const { isLongLeadTime, showSwatchRequest, isPriceHidden, variantId } = userBuyBoxViews({
        custom_fields: productData?.custom_fields,
        options: productData?.options,
        baseVariantId,
        variant_id: productData.variant_id,
    })

    function setQuantity(num){
        dispatch({
            type: "setQty",
            data: num
        })

        setQty(num)
    }


    return(
        <>
            <form onSubmit={(e) => e.preventDefault()}>
                {productData?.product_sets && (
                    <SetItemsList 
                        brand={productData?.brand} 
                    />
                )}


                {!isPriceHidden && productData?.price ? (
                    <DynamicPrice 
                        showAffirm
                        isProductSet={!!productData?.product_sets}
                        {...{ qty, productData, isTrade }}
                    />
                ):null}


                {!Array.isArray(productData?.options) && (
                    <Divider className={clsx(utils.my_1)} isSpacer />
                )}
                
                <OfferContextProvider>
                    <ErrorBoundary>
                        {
                            (productData?.options && productData?.options.length) || 
                            (productData?.options && !productData?.options.length && 
                            (productData?.product_sets && Object.keys(productData?.product_sets).length) ) ? (
                                <Options 
                                    product_id={productData?.product_id}
                                    options={productData?.options} 
                                    sets={productData?.product_sets}
                                    addons={productData?.addon_products}
                                    hideSwatch={productData?.custom_fields?.hideswatch}
                                    stockedOptions={productData?.custom_fields?.stocked_options}
                                    specialOrderOptions={productData?.custom_fields?.special_order_options}
                                    brand={productData?.brand?.name}
                                    ref={optionsRef}
                                    {...{ isMobile, bcOptions }}
                                /> 
                            ):null
                        }
                    </ErrorBoundary>

                    {(!productData?.product_sets && productData?.availability !== "disabled") && (
                        <OfferOptions 
                            isOpen={!productData?.options || (Array.isArray(productData?.options) && !productData?.options.length)}
                            price={productData?.price}
                            sku={productData?.sku}
                            category={productData?.categories?.[0]?.name ?? productData?.categories?.[0]}
                            name={productData?.name}
                            qty={qty}
                            context={{
                                price: state?.price,
                            }}
                        />
                    )}
                </OfferContextProvider>

                

                {(
                    !isPriceHidden && 
                    productData?.price && 
                    (Array.isArray(productData?.options) && productData?.options.length >= 4)
                ) ? (
                        <>
                            <DynamicPrice 
                                {...{ isTrade, qty, productData }}
                                showAffirm
                                showWasPrice={false}
                                isProductSet={!!productData?.product_sets}
                            />      

                            <Divider className={clsx(utils.mb_2)} isSpacer />
                        </>
                    ):null
                }


                {isPriceHidden && (
                    <p className={Style.priceHiddenLabel}>
                        {productData?.custom_fields?.price_hidden_label}
                    </p>
                )}


                <div className={clsx(Style.atcBlock, Style.atcStack)}>
                   
                    <QuantityButton 
                        min={productData?.minimum_qty}
                        max={productData?.maximum_qty}
                        value={qty} 
                        qty={(num) => setQuantity(num)}
                        id="pdp"
                        notValidQty={state.errorAttributes}
                    />

                    {(productData?.price !== 0 && (productData?.availability !== "disabled" || !productData?.availability)) && (
                        <aside className={Style.purchaseButtons}>
                            {/* Standard ATC Button */}
                            <AddToCartButton 
                                {...{ 
                                    qty, 
                                    optionsRef,
                                    userId,
                                    variantId,
                                    maximum_qty: productData?.maximum_qty ?? 999,
                                    minimum_qty: productData?.minimum_qty ?? 1, 
                                    name: productData?.name ?? null, 
                                    price: productData?.price ?? 0, 
                                    categories: productData?.categories, 
                                    brand: productData?.brand.name, 
                                    family: productData?.family_code 
                                }}
                                // notValidQty={(bool) => setNotValidQty(bool)}
                                productId={productData?.product_id}
                                isProductSet={productData?.product_sets !== null}
                                contextState={productData?.product_sets !== null ? "sets": "options"}
                                isDisabled={productData?.price === 0 }
                            />

                            <OrDivider />

                            {/* Buy Now Button */}
                            <AddToCartButton 
                                {...{ 
                                    buyNow: true,
                                    qty, 
                                    optionsRef,
                                    userId,
                                    variantId,
                                    maximum_qty: productData?.maximum_qty ?? 999,
                                    minimum_qty: productData?.minimum_qty ?? 1, 
                                    name: productData?.name ?? null, 
                                    price: productData?.price ?? 0, 
                                    categories: productData?.categories, 
                                    brand: productData?.brand.name, 
                                    family: productData?.family_code 
                                }}
                                // notValidQty={(bool) => setNotValidQty(bool)}
                                className={Style.buyNow}
                                productId={productData?.product_id}
                                isProductSet={productData?.product_sets !== null}
                                contextState={productData?.product_sets !== null ? "sets": "options"}
                                isDisabled={productData?.price === 0 }
                            />
                        </aside>
                    )}  

                    {/* availability === "disabled" || availability === false && !is_price_hidden_label; "then show the out of stock button" */}
                    {/* availability === "disabled" || availability === false && is_price_hidden_label !== ""; "then just display the label"  */}

                    {((productData?.availability === "disabled" || !productData.availability)) && (
                        <aside className={Style.purchaseButtons}>
                            <div className={Style.outOfStock}>
                                {productData?.custom_fields?.price_hidden_label ? "Not available online" : "Out of stock"}
                            </div>
                        </aside>
                    )}
                </div>
                
            </form>

           
            <Divider className={clsx(utils.my_1)} isSpacer />

            {showSwatchRequest && (                    
                <SwatchOrderButton />
            )}

            <Shipping 
                customFields={productData?.custom_fields}
                isLongLeadTime={isLongLeadTime}
            />


            {productData?.addon_products && (
                <Addons 
                    addon={productData.addon_products} 
                    brand={productData.brand}
                    family={productData?.family_code}
                    parentId={productData?.product_id}
                    userId={userId}
                />

            )}

            

            <div className={Style.designConsult}>
                <p className={Style.consultItem}>
                    <IconShoppingBag width="25" height="25" className={utils.me_1} />
                    <strong className={Style.consultText}>
                        {isTrade ? (
                            <>
                                Welcome Trade Member!<br/> Enjoy nationwide delivery.
                            </>
                        ):(
                            <>
                                Shopping for a Client?
                                <br/>
                                
                                <Link
                                    href="/c/trade"
                                    target="_blank"
                                    title="learn about our trade program"
                                    className={Style.btnLink}
                                >    
                                    Unlock your trade benefits
                                </Link>
                            </>
                        )}
                        
                    </strong> 
                </p>


                {productData?.brand?.name && (
                    <p className={Style.consultItem}>
                        <IconWarranty width="40" height="40" className={utils.me_1} />

                        <strong className={Style.consultText}>
                            Covered by the {productData?.brand?.name} warranty. 
                        </strong>
                    </p>
                )}
            
            </div>


            <Divider className={clsx(utils.mt_3)} />


            <div className={Style.designConsult}>
                <div className={Style.consultCtaCntr}>
                    <h4 className={utils.fs_5}>How can we help?</h4>
                    <p className={Style.consultText}>
                        Contact us for assistance, a free design consultation or to order by phone.
                    </p>
                </div>

                <div className={Style.consultCtaCntr}>
                    <Button 
                        onClick={() => window.open(`tel:${process.env.phone}`, "_self")}
                        className={clsx(utils.mb_2, utils.w_100)}
                        size="md"
                        type="button"
                        color="white"
                    >
                        <small className={clsx(utils.text_ocean, Style.contactBtn)}>
                            {process.env.phone}
                        </small>
                    </Button>
                    
                    <Button 
                        size="md"
                        type="button"
                        color="white"
                        onClick={triggerChat}
                    >
                        <IconChatBubble width="30" height="30" fill="#4c7b9a" />

                        <small className={clsx(utils.text_ocean, Style.contactBtn)}>
                            Chat Now
                        </small>
                    </Button>

                    <PageModal
                        ctaText="Feedback Survey"
                        formHandle="pdpFeedbackForm"
                        marginTop={2}
                        marginBottom={2}
                        buttonColor="white"
                        buttonType="button"
                        buttonSize="md"
                        buttonTextClassName={Styles.btn_text}
                    />
                </div>
            </div>
        </>
    )
}



export default BuyBox