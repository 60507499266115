"use client"

import clsx from "clsx"
import { IconChevronLeft } from 'components/icons/IconChevronLeft';
import { useEffect, useMemo, useState } from "react";
import Style from "./Truncate.module.scss"
import useMeasure from 'react-use/lib/useMeasure';

/**
 * Truncates a block with a see more button at the bottom
 */

function Truncate({ 
    maxHeight = "unset", 
    children, 
    buttonText, 
    shouldTruncate = 400, 
    className 
}){
    const [ show, setShow ] = useState(false)
    const [ containerRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const willTruncate = useMemo(() => {
        const ofType = typeof shouldTruncate;

        switch(ofType){
            case "number": return height > shouldTruncate;
            case "boolean": return shouldTruncate
            default: return false;
        }

    }, [ height, shouldTruncate ])


    return(
        <div 
            className={clsx(Style.block, show && Style.show, className)} 
            style={{ maxHeight: ((willTruncate && !show) ? maxHeight : "unset"), overflow: (show ? "unset" : "hidden") }}
        >
            <div className={Style.content} ref={containerRef}>
                {children}
            </div>

            {(willTruncate && !show) && (
                <button 
                    className={clsx(Style.button, show && Style.maxBtn)} 
                    type="button" 
                    onClick={() => setShow(!show)}
                >
                    {buttonText}
                    <IconChevronLeft width="25" height="25" />
                </button>
            )}
        </div>        
    )
}


export default Truncate;