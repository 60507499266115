import { blurImage } from 'lib/Constants/blurImage';
import Image from "next/image";
import Style from "./ImageZoom.module.scss";
import { use, useEffect, useRef, useState } from "react";
import clsx from "clsx";



function ImageZoom({ 
    className, 
    src, 
    alt, 
    zoomSrc, 
    width = 800,
    height = 800
}) {
    const resultRef = useRef(null);
    const lens = useRef(null);
    const imgRef = useRef(null);
    const cx = useRef(null);
    const cy = useRef(null);

    const [ zoomResult, setZoomResult ] = useState(false);


    function getCursorPos(e) {
        let img, x = 0, y = 0;

        e = e || window.event;
        /* Get the x and y positions of the image: */
        img = imgRef.current.getBoundingClientRect();

        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - img.left;
        y = e.pageY - img.top;

        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;

        return { x, y };
    }



    function moveLens(e, img) {
        if( lens.current ){
            let pos, x, y;

            /* Prevent any other actions that may occur when moving over the image */
            e.preventDefault();

            /* Get the cursor's x and y positions: */
            pos = getCursorPos(e);

            /* Calculate the position of the lens: */
            x = pos.x - (lens.current.offsetWidth / 2);
            y = pos.y - (lens.current.offsetHeight / 2);

            /* Prevent the lens from being positioned outside the image: */
            if (x > img.width - lens.current.offsetWidth) {x = img.width - lens.current.offsetWidth;}
            if (x < 0) {x = 0;}
            if (y > img.height - lens.current.offsetHeight) {y = img.height - lens.current.offsetHeight;}
            if (y < 0) {y = 0;}

            lens.current.style.left = x + "px";
            lens.current.style.top = y + "px";

            /* Display what the lens "sees": */
            resultRef.current.style.backgroundPosition = "-" + (x * cx.current) + "px -" + (y * cy.current) + "px";
        }
    }


    

    useEffect(() => {
        if( zoomResult ){
            lens.current = document.createElement("div");
            lens.current.setAttribute("class", Style.lens);
            imgRef.current.parentElement.insertBefore(lens.current, imgRef.current);

            lens.current.addEventListener("mousemove", (e) => moveLens(e, imgRef.current));

            cx.current = resultRef.current.offsetWidth / lens.current.offsetWidth;
            cy.current = resultRef.current.offsetHeight / lens.current.offsetHeight;

            resultRef.current.style.backgroundImage = `url(${zoomSrc})`;
            resultRef.current.style.backgroundSize = (imgRef.current.width * cx.current) + "px " + (imgRef.current.height * cy.current) + "px";
        }

        return () => {
            lens.current?.remove();
            resultRef.current = null;
            cx.current = null;
            cy.current = null;
        }

    }, [ 
        zoomResult,
        resultRef.current,
        imgRef.current,
        zoomSrc,
        cx.current,
        cy.current,
        lens.current
    ])



    return(
        <figure 
            className={clsx(Style.block, zoomResult && Style.active)}
            onClick={() => setZoomResult(!zoomResult)}
        >
            <div 
                className={clsx(Style.result)} 
                ref={resultRef}
                id="results"
            />   

            <Image 
                ref={imgRef}
                placeholder="blur"
                blurDataURL={blurImage}
                onMouseMove={(e) => moveLens(e, imgRef.current)}
                {...{ width, height, alt, src, className }}
            />

            {/* <div className={clsx(Style.clickZoom, clickOver && Style.clickZoomActive)}>
                <IconZoomIn className={Style.zoomIcon} />
                <span className={Style.zoomText}>
                    Click to Zoom
                </span>
            </div> */}
        </figure>
    )
}

export default ImageZoom;