"use client"

import QuantityButton from "components/product/QuantityButton";
import { useContext, useEffect, useState } from "react";
import { ProductOptionContext } from 'components/applications/pip/contexts/ProductOptionContext';


function SetQtyController({ quantity, productID, name }){
    const [ state, dispatch ] = useContext(ProductOptionContext);
    const [ qty, setQty ] = useState(quantity);


     // update the global state quantity that affects all products
     useEffect(() => {
        dispatch({
            type: "updateSetProductQuantity",
            data: {
                product_id: productID,
                qty: qty * state.qty
            }
        })

    }, [ state.qty ])


    // update the quantity of a single product in the set
    useEffect(() => {
        dispatch({
            type: "updateSetProductQuantity",
            data: {
                product_id: productID,
                qty: qty
            }
        })

    }, [qty])



    return(
        <QuantityButton 
            value={state.sets?.products?.[productID]?.quantity ?? 1} 
            qty={async (num) => {
                setQty(num);

                const reactToast = await import("react-toastify");
                reactToast.toast(`You got it! ${num} ${name}${num > 1 ? `s` : ""} in your set.`);
            }}
        /> 
    )
}


export default SetQtyController;