"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/forms/Button';
import { useRouter } from "next/navigation";
import Style from './ProductQuestions.module.scss'
import utils from 'styles/globals/utils.module.scss';
import makeQueryString from 'lib/Utils/makeQueryString';

function QuestionHeader({ children, returnUrl }){
    const router = useRouter()
    const pageQuery = makeQueryString({return: returnUrl})
    
    return(
        <header className={Style.heading}>
            <h2 className={utils.fs_2}>
                Questions &amp; Answers
            </h2>

            {children}

            <Button 
                color="ocean" 
                type="button" 
                onClick={() => router.push("/p/ask-a-question" + pageQuery)}
            >
                Ask a Question
            </Button>
        </header>
    )
}


export default QuestionHeader;